import { CButton, CButtonGroup, CTooltip } from "@coreui/react";
import {
  INVOICE_STATUS_APPROVED_BY_SET,
  INVOICE_STATUS_CANCELLATION_FAILED,
  INVOICE_STATUS_ERROR,
  INVOICE_STATUS_PENDING,
  INVOICE_STATUS_RESEND_REQUESTED,
  INVOICE_STATUS_SET_UNKNOWN_ERROR,
  INVOICE_STATUS_UPLOADED_TO_SET,
  Invoice,
} from "../../models/invoice";
import { useEffect, useState } from "react";
import { useSub } from "../../hooks/pub-sub";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import { INVOICES_DESTROY, INVOICES_UPDATE } from "../../auth/permissions";

interface InvoiceCrudAtionsProps {
  invoice: Invoice;
  onCancellationClick: (_: Invoice) => void;
  onCancellationRetryClick: (_: Invoice) => void;
  onCancellationRevertClick: (_: Invoice) => void;
  onUpdateClick: (_: Invoice) => void;
  onDeleteClick: (_: Invoice) => void;
  onConsultByCdcClick: (_: Invoice) => void;
  onResendClick: (_: Invoice) => void;
  onSendEmailClick: (_: Invoice) => void;
}

const InvoiceCrudActions: React.FC<InvoiceCrudAtionsProps> = ({
  invoice,
  onUpdateClick,
  onDeleteClick,
  onConsultByCdcClick,
  onCancellationClick,
  onCancellationRetryClick,
  onCancellationRevertClick,
  onResendClick,
  onSendEmailClick,
}) => {
  const [currentInvoice, setCurrentInvoice] = useState(invoice);

  useEffect(() => {
    setCurrentInvoice(invoice);
  }, [invoice]);

  useSub(`invoice_${invoice.id}`, (invoice: Invoice) => {
    setCurrentInvoice(invoice);
  });

  const cancelButton = (
    <PermissionRequiredComponent permissionName={INVOICES_UPDATE}>
      <CTooltip content="Anular">
        <CButton
          className="text-white"
          color="secondary"
          onClick={() => {
            onCancellationClick(currentInvoice);
          }}
        >
          <i className="fa fa-ban"></i>
        </CButton>
      </CTooltip>
    </PermissionRequiredComponent>
  );

  const editButton = (
    <PermissionRequiredComponent permissionName={INVOICES_UPDATE}>
      <CTooltip content="Editar">
        <CButton
          className="text-white"
          color="warning"
          onClick={() => {
            onUpdateClick(currentInvoice);
          }}
        >
          <i className="fa fa-pencil"></i>
        </CButton>
      </CTooltip>
    </PermissionRequiredComponent>
  );

  const deleteButton = (
    <PermissionRequiredComponent permissionName={INVOICES_DESTROY}>
      <CTooltip content="Eliminar">
        <CButton
          className="text-white"
          color="danger"
          onClick={() => {
            onDeleteClick(currentInvoice);
          }}
        >
          <i className="fa fa-trash"></i>
        </CButton>
      </CTooltip>
    </PermissionRequiredComponent>
  );

  const invoiceDateTime = new Date(currentInvoice.invoiceDate!).getTime();
  const now = new Date().getTime();

  const invoiceOlderThan3Days = invoiceDateTime < now - 3 * 24 * 60 * 60 * 1000;

  const shouldShowCancelButton =
    currentInvoice.status === INVOICE_STATUS_APPROVED_BY_SET &&
    !invoiceOlderThan3Days;

  const shouldShowEditAndDeleteButton =
    currentInvoice.status === INVOICE_STATUS_PENDING ||
    currentInvoice.status === INVOICE_STATUS_ERROR ||
    currentInvoice.status === INVOICE_STATUS_SET_UNKNOWN_ERROR;

  const consultByCdcButton = (
    <PermissionRequiredComponent permissionName={INVOICES_UPDATE}>
      <CTooltip content="Consultar por CDC">
        <CButton
          className="text-white"
          color="primary"
          onClick={() => {
            onConsultByCdcClick(invoice);
          }}
        >
          <i className="fa fa-question-circle"></i>
        </CButton>
      </CTooltip>
    </PermissionRequiredComponent>
  );

  const shouldShowConsultByCdcButton =
    currentInvoice.status === INVOICE_STATUS_UPLOADED_TO_SET ||
    currentInvoice.status === INVOICE_STATUS_ERROR ||
    currentInvoice.status === INVOICE_STATUS_RESEND_REQUESTED ||
    currentInvoice.setResponseCode === "1001";

  const resendButton = (
    <PermissionRequiredComponent permissionName={INVOICES_UPDATE}>
      <CTooltip content="Reenviar">
        <CButton
          className="text-white"
          color="success"
          onClick={() => {
            onResendClick(currentInvoice);
          }}
        >
          <i className="fa fa-refresh"></i>
        </CButton>
      </CTooltip>
    </PermissionRequiredComponent>
  );

  const shouldShowResendButton =
    currentInvoice.status === INVOICE_STATUS_ERROR ||
    currentInvoice.status === INVOICE_STATUS_UPLOADED_TO_SET;

  const sendEmailButton = (
    <PermissionRequiredComponent permissionName={INVOICES_UPDATE}>
      <CTooltip content="Enviar Correo">
        <CButton
          className="text-white"
          color="info"
          onClick={() => {
            onSendEmailClick(invoice);
          }}
        >
          <i className="fa fa-envelope"></i>
        </CButton>
      </CTooltip>
    </PermissionRequiredComponent>
  );

  const shouldShowSendEmailButton =
    invoice.status === INVOICE_STATUS_APPROVED_BY_SET &&
    invoice.emailSent === false;

  const retryCancellationButton = (
    <PermissionRequiredComponent permissionName={INVOICES_UPDATE}>
      <CTooltip content="Reintentar Anulación">
        <CButton
          className="text-white"
          color="warning"
          onClick={() => {
            onCancellationRetryClick(currentInvoice);
          }}
        >
          <i className="fa fa-rotate-right"></i>
        </CButton>
      </CTooltip>
    </PermissionRequiredComponent>
  );

  const shouldShowRetryCancellationButton =
    invoice.status === INVOICE_STATUS_CANCELLATION_FAILED;

  const revertCancellationButton = (
    <PermissionRequiredComponent permissionName={INVOICES_UPDATE}>
      <CTooltip content="Revertir Anulación">
        <CButton
          className="text-white"
          color="success"
          onClick={() => {
            onCancellationRevertClick(currentInvoice);
          }}
        >
          <i className="fa fa-backward"></i>
        </CButton>
      </CTooltip>
    </PermissionRequiredComponent>
  );

  const shouldShowRevertCancellationButton =
    invoice.status === INVOICE_STATUS_CANCELLATION_FAILED;

  return (
    <td className="text-center">
      <CButtonGroup>
        {shouldShowCancelButton ? cancelButton : <></>}
        {shouldShowEditAndDeleteButton ? (
          <>
            {editButton}
            {deleteButton}
          </>
        ) : (
          <></>
        )}
        {shouldShowConsultByCdcButton ? consultByCdcButton : <></>}
        {shouldShowResendButton ? resendButton : <></>}
        {shouldShowRetryCancellationButton ? retryCancellationButton : <></>}
        {shouldShowRevertCancellationButton ? revertCancellationButton : <></>}
        {shouldShowSendEmailButton ? sendEmailButton : <></>}
      </CButtonGroup>
    </td>
  );
};

export default InvoiceCrudActions;
